import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import CategoryInputField from "../../Components/CategoryInputField/CategoryInputField.js";
import Editor from "../../Components/Editor";
import {addNewBlog, autoId} from "../../Services/firestore_service";
import "./AddBlog.css";
import {LinearProgress} from "@mui/material";
import {Box} from "@mui/system";
import {uploadFile} from "../../Services/storage_service";

function AddBlog() {
    const navigate = useNavigate();

    const editorCore = React.useRef(null);

    const titleInput = React.createRef();
    const featureImageInput = React.createRef();
    const slugInput = React.createRef();
    const excerptInput = React.createRef();
    const categoryInput = React.createRef();

    const [featureImage, setFeatureImage] = useState(null);
    const [content, setContent] = useState({});
    const [title, setTitle] = useState("");
    const [featured, setFeatured] = useState(false);
    const [slug, setSlug] = useState("");
    const [excerpt, setExcerpt] = useState("");
    const [progress, setProgress] = useState(0);

    const [categoriesArray, setcategoriesArray] = useState([]);

    const author = {
        name: "Saumya Kumar",
        profileImage: "https://picsum.photos/200",
    };

    function handleImageSelection(e) {
        console.log(e.target.files);
        var imageUrl = URL.createObjectURL(e.target.files[0]);
        setFeatureImage(imageUrl);
        setProgress(1);
        uploadFile(e.target.files[0])
            .then((url) => {
                console.log("image url", url);
                setFeatureImage(url);
                setProgress(0);
            })
            .catch((e) => {
                setProgress(0);
                setFeatureImage(null);
            });
    }

    const handleInitialize = React.useCallback((instance) => {
        editorCore.current = instance;
    }, []);

    const handleSave = (event, publish) => {
        event.preventDefault();

        editorCore.current.save().then((editorData) => {
            setContent(editorData);

            var data = gatherBlogData(publish, editorData);

            if (publish) {
                if (!validateFields(data)) {
                    // validate all field before publish
                    return;
                }
            }

            // at least have title to save

            if (title.length > 0) {
                addNewBlog(data)
                    .then(() => {
                        if (!publish) {
                            navigate(`/blog/edit/${data.id}`, {state: data});
                        } else {
                            navigate("/blog");
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        toast.error("Some error occurred. 🚫");
                    });
            } else {
                toast.error("Provide Title");
                titleInput.current.focus();
            }
        });
    };

    const validateFields = (data) => {
        console.log("validating data", data);
        if (data.title.length < 1) {
            titleInput.current.focus();
            toast.error("Please Provide title", {autoClose: 3000});
            return false;
        } else if (data.categories.length < 1) {
            categoryInput.current.focus();
            toast.erdata.ror("Please Provide a category", {autoClose: 3000});
            return false;
        } else if (data.slug.length < 1) {
            slugInput.current.focus();
            toast.error("Please Provide slug", {autoClose: 3000});
            return false;
        } else if (data.excerpt.length < 1) {
            excerptInput.current.focus();
            toast.error("Please Provide Excerpt", {autoClose: 3000});
            return false;
        } else if (data.featureImage == null) {
            featureImageInput.current.focus();
            toast.error("Please Provide feature image", {autoClose: 3000});
            return false;
        } else if (data.content.blocks.length < 1) {
            toast.error("Please Provide Content");
            return false;
        } else {
            return true;
        }
    };

    const gatherBlogData = (publish, savedData) => {
        var id = autoId();

        return {
            id: id,
            published: publish,
            title: title,
            featured: featured,
            author: author,
            categories: categoriesArray,
            slug: slug,
            excerpt: excerpt,
            featureImage: featureImage,
            content: savedData ? savedData : content,
        };
    };

    const handleCategoryAdd = (category) => {
        setcategoriesArray([...categoriesArray, category]);
    };

    const handleDelete = (index) => {
        console.log("handle delete claled", index);
        categoriesArray.splice(index, 1);
        setcategoriesArray([...categoriesArray]);
    };

    return (
        <div className="editblog">
            <div className="edit-form-group">

                <div className="form-group">
                    <label htmlFor="blogTitle">Blog Title</label>
                    <div className="input-container">
                        <input
                            ref={titleInput}
                            type="text"
                            id="blogTitle"
                            placeholder="Title"
                            required
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>

                <CategoryInputField
                    inputRef={categoryInput}
                    categories={categoriesArray}
                    onCategoryAdd={handleCategoryAdd}
                    handleDelete={handleDelete}
                />
                <label>Slug</label>

                <input
                    type="text"
                    ref={slugInput}
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="slugHelp"
                    placeholder="Slug (ex:- prints-in-python, what-is-saas)"
                    required
                />
                <br/>
                <small id="slugHelp" className="slugtext">
                    (A url friendly text for the blog)
                </small>
                <br/>
                <div className="excerpt-container">
                    <label>Excerpt</label>
                    <textarea
                        type="text"
                        ref={excerptInput}
                        value={excerpt}
                        onChange={(e) => setExcerpt(e.target.value)}
                        className="form-control "
                        id="exampleInputEmail1"
                        aria-describedby="excerptHelp"
                        placeholder="Excerpt"
                        required
                    />
                </div>
                <br/>
                <small id="excerptHelp" className="excerpttext">
                    (Small description ofcontent)
                </small>
                <br/>

                <div className="featured">
                    <label className="feature-head">Featured Image</label>
                    <div className="feature-img-container">
                        <Box sx={{width: "100%"}}>
                            {progress ? <LinearProgress/> : null}
                        </Box>
                        <div className="image-area">
                            {featureImage ? (
                                <img
                                    className="blog-image"
                                    src={featureImage}
                                    alt="featureimg"
                                    width={400}
                                    height={450}
                                />
                            ) : (
                                <input
                                    ref={featureImageInput}
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageSelection}
                                    className="add-image"
                                />
                            )}
                        </div>
                    </div>

                    {featureImage ? (
                        <button
                            type="button"
                            className="remove-image-btn"
                            onClick={() => setFeatureImage(null)}
                        >
                            Remove Image
                        </button>
                    ) : null}
                </div>
            </div>

            {/* Editorjs container */}
            <div className="content-container">
                <Editor
                    onInit={handleInitialize}
                    initalData={content ? content : {blocks: []}}
                    id={"editorId"}
                />
                <div id="editor"></div>
            </div>
            <div className="editblog-btm-container">
                <h6>Published</h6>
                <div className="form-check-inline">
                    <input
                        className="form-check-input"
                        checked={featured}
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        onChange={(e) => setFeatured(true)}
                        value={true}
                    />
                    <label className="form-check-label">True</label>
                    <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={!featured}
                        onChange={(e) => setFeatured(false)}
                        value={false}
                    />
                    <label className="form-check-label">False</label>
                </div>

                <div className="btm-form-group">
                    <label>Author</label>
                    <div className="author-card">
                        <img src={author.profileImage} className="avatar-image" alt="..."/>
                        <h5 className="author-card-title">{author.name}</h5>
                    </div>
                </div>

                <div className="btn-group" role="group" aria-label="Basic example">
                    <button
                        type="button"
                        className="btn-primary"
                        onClick={(e) => handleSave(e, false)}
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        className="btn-primary"
                        onClick={(e) => handleSave(e, true)}
                    >
                        Publish
                    </button>
                    <button
                        type="button"
                        className="btn-primary"
                        onClick={() => navigate("/blog")}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AddBlog;
