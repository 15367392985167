import React, {useState} from "react";
import "./LoginPage.css";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../../firebase";
import {useAuth} from "../../AuthContext";

const LoginPage = () => {
    const {login} = useAuth();
    // const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = (e) => {
        e.preventDefault();

        if (email.trim() === "" || password.trim() === "") {
            toast.error("Please enter your email and password");
            return;
        }
        signInWithEmailAndPassword(auth, email, password)
            .then((credentials) => {
                login(credentials.user)
                toast.success("Login successful", {position: toast.POSITION.TOP_CENTER, autoClose: 1000});
            })
            .catch((err) => {
                console.log(err)
            });
    };
    return (
        <>
            <div className="container">
                <h1>Login Page</h1>
                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Login</button>
                </form>

            </div>
            <ToastContainer/>
        </>
    );
};

export default LoginPage;

