import React, {useEffect, useState} from "react";
import "./BlogContent.css";
import Skeleton from "react-loading-skeleton";
import Blocks from "editorjs-blocks-react-renderer";
import {db} from '../../../../firebase'
import {collection, getDocs, limit, query, where} from "firebase/firestore";

const BlogContent = ({slug}) => {
    const [blog, setBlog] = useState(null);

    const fetchBlogBySlug = async (slug) => {
        const data = await getDocs(query(
            collection(db, "blog"),
            // where("published", "==", true),
            where("slug", "==", slug),
            limit(1)
        ));

        console.log("Blogs " + data.docs.length)

        const blogs = data.docs.map((docData) => ({
            ...docData.data(),
            id: docData.id,
        }));

        if (blogs.length !== 0) {
            console.log("inside")
            return blogs.at(0);
        } else {
            return null;
        }
    };

    useEffect(() => {
        fetchBlogBySlug(slug).then((blog) => {
            if (blog == null) {
                setBlog(null);
            } else {
                setBlog(blog);

            }
        });
    }, [slug]);


    const config = {
        code: {
            className: "language-js",
        },
        delimiter: {
            className: "editor-delimiter",
        },
        embed: {
            className: "editor-embed",
        },
        header: {
            className: "editor-header",
        },
        image: {
            className: "editor-image",
            actionsClassNames: {
                stretched: "stretched",
                withBorder: "withBorder",
                withBackground: "withBackground",
            },
        },
        list: {
            className: "editor-list",
        },
        paragraph: {
            className: "editor-para",
            actionsClassNames: {
                alignment: "", // This is a substitution placeholder: left or center.
            },
        },
        quote: {
            className: "editor-quote",
        },
        table: {
            className: "table-auto",
        },
    };

    return (
        <div className='blog-body'>
            {blog ? (
                <div className="blog-content">

                    <img
                        src="/images/ff.svg"
                        style={{
                            position: "absolute",
                            height: "100px",
                            width: "100px",
                            top: "15%",
                            right: "20%",
                            zIndex: "0",
                            transform: "rotate(45deg)",
                            opacity: "0.2"

                        }}


                    />

                    <section className="blog-content-article">
                        <h1 id="blog-title">{blog.title}</h1>
                        <div className="blogcontent-detail">
                            {blog.categories.slice(0, 2).map((category, index) => (
                                <p className="blogcontent-detail-text" key={index}>
                                    {category}
                                </p>
                            ))}
                        </div>
                        <div className="blogimg-container">
                            <img
                                src={blog.featureImage}
                                alt="cover"
                                width={400}
                                height={300}
                                style={{
                                    zIndex: "1",
                                }}
                            />

                        </div>
                        <div className="blogcontent-detail">
                            <p className="blogcontent-detail-text">
                                {blog.updatedOn
                                    ? blog.updatedOn.toDate().toLocaleTimeString()
                                    : "not updated yet"}
                            </p>

                            <p className="blogcontent-detail-text">
                                {blog.updatedOn
                                    ? blog.updatedOn.toDate().toLocaleDateString()
                                    : "not updated yet"}
                            </p>
                        </div>
                        <br/>
                        <div className={'blog-blocks'}><Blocks data={blog.content} config={config}/></div>
                    </section>
                </div>
            ) : (
                <div className="blog-content">
                    <section className="blog-content-article">
                        <h1>
                            <Skeleton height={30} width={200}/>
                        </h1>
                        <div className="blogcontent-detail">
                            <Skeleton height={20} width={100}/>
                            <Skeleton height={20} width={100}/>
                        </div>
                        <div className="blogimg-container">
                            <Skeleton height={350} width={400}/>
                        </div>
                        <div className="blogcontent-detail">
                            <p className="blogcontent-detail-text">
                                <Skeleton height={16} width={80}/>
                            </p>

                            <p className="blogcontent-detail-text">
                                <Skeleton height={16} width={120}/>
                            </p>
                        </div>
                        <br/>
                        <Skeleton count={5} height={20}/>
                    </section>
                </div>
            )}
        </div>
    );
};

export default BlogContent;
