import React, { useState, useEffect } from "react";
import "./Header.css";
import { auth } from "../../firebase";
import { useAuth } from "../../AuthContext";
import { HashLink } from "react-router-hash-link";
import Modal from "./Model";

const Header = () => {
    const [isMobile, setIsMobile] = useState(false);
    const { logout } = useAuth();

    const [modalOpen, setModalOpen] = useState(false);

    const handleLogout = async () => {
        try {
            await auth.signOut();
            logout();
        } catch (error) {
            console.log('Error logging out:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const isSmallScreen = window.innerWidth <= 768;
            setIsMobile(isSmallScreen && isMobile); // Only update if small screen and already mobile view
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMobile]);

    return (
        <nav className="navbar">
            <HashLink to="/" className="navmainlink">
                <div className="nav-logo">
                    <h1 style={{ userSelect: "none" }} className="nav-logo-text">Website Admin</h1>
                </div>
            </HashLink>
            <ul
                className={isMobile ? "nav-links-mobile active" : "nav-links"}
                onClick={() => setIsMobile(false)}
            >
                <li>
                    <HashLink to="/Dashboard" smooth={true} duration={300} className="item">
                        Dashboard
                    </HashLink>
                </li>
                <li>
                    <HashLink to="/Blog" smooth={true} duration={300} className="item">
                        Blog
                    </HashLink>
                </li>
                <li>
                    <HashLink to="/contactUs" smooth={true} duration={300} className="item">
                        Contacts
                    </HashLink>
                </li>
                <li>
                    <HashLink onClick={() => {
                        setModalOpen(true);
                    }}
                        smooth={true} duration={300} className="item">
                        Profile
                    </HashLink>

                </li>
                <li>
                    <button onClick={handleLogout} className="logout-btn">Logout</button>
                </li>
            </ul>
            {modalOpen && <Modal setOpenModal={setModalOpen} />}
            <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
                {isMobile ? <i className="fas fa-times"></i> : <i className="fa-solid fa-bars"></i>}
            </button>
        </nav >
    );
};

export default Header;
