import React, { useState, useEffect } from 'react';
import "./Modal.css";
import {
    ref,
    uploadBytes,
    getDownloadURL,
} from "firebase/storage";
import { auth, storage, firestore } from '../../firebase';
import { FcUpload } from "react-icons/fc";
import { MdModeEdit } from "react-icons/md";
import { CgProfile } from "react-icons/cg";

function Modal({ setOpenModal }) {
    const [editMode, setEditMode] = useState(false);
    const [name, setName] = useState('Ashish Adarsh');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('123-456-7890');
    const [designation, setDesignation] = useState('Software Engineer');
    const [newName, setNewName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [newPhone, setNewPhone] = useState('');
    const [newDesignation, setNewDesignation] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [imageUpload, setImageUpload] = useState(null);

    useEffect(() => {
        // Fetch user's profile data and image URL on component mount
        const fetchUserData = async () => {
            try {
                const currentUser = auth.currentUser;
                if (currentUser) {
                    setEmail(currentUser.email);
                    const userDocRef = firestore.doc(`users/${currentUser.uid}`);
                    const userData = await userDocRef.get();
                    if (userData.exists()) {
                        const userDataObj = userData.data();
                        setImageUrl(userDataObj.imageUrl || '');
                    }
                    const imageRef = ref(storage, `images/${currentUser.email}/displaypic`);
                    const url = await getDownloadURL(imageRef);
                    setImageUrl(url);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    const uploadFile = () => {
        if (imageUpload == null) return;
        const currentUser = auth.currentUser;
        if (currentUser) {
            const imageRef = ref(storage, `images/${currentUser.email}/displaypic`);
            uploadBytes(imageRef, imageUpload).then(async (snapshot) => {
                const url = await getDownloadURL(snapshot.ref);
                setImageUrl(url);
                // Save image URL to user's profile data
                const userDocRef = firestore.doc(`users/${currentUser.uid}`);
                await userDocRef.set({ imageUrl: url }, { merge: true });
            }).catch(error => {
                console.error('Error uploading image:', error);
            });
        }
    };

    const handleEdit = () => {
        setEditMode(true);
        setNewName(name);
        setNewEmail(email);
        setNewPhone(phone);
        setNewDesignation(designation);
    };

    const handleSave = () => {
        setName(newName);
        setEmail(newEmail);
        setPhone(newPhone);
        setDesignation(newDesignation);
        setEditMode(false);
    };


    return (
        <div className="modalBackground">
            <div className="modalContainer">
                <div className="titleCloseBtn">
                    <button
                        onClick={() => {
                            setOpenModal(false);
                        }}
                    >
                        X
                    </button>
                </div>
                <div className="title">
                    <h1>Your Profile</h1>
                </div>
                <hr class="hr-custom" />
                <div className="body">
                    <div className="top-section">
                        <img className='imageProf' src={imageUrl} alt="Profile Picture" />
                        <div class="input-container">
                            <label for="upload-photo" class="input-label">
                                <span class="input-icon">  <CgProfile /></span>
                                Choose
                            </label>
                            <input
                                id="upload-photo"
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                    setImageUpload(event.target.files[0]);
                                }}
                            />
                            <button className='uploadImg' onClick={uploadFile}> <FcUpload /> Upload</button>
                        </div>
                    </div>
                    <div className="bottom-section">
                        <div>
                            {editMode ? (
                                <>
                                    <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                                    <input type="email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                                    <input type="tel" value={newPhone} onChange={(e) => setNewPhone(e.target.value)} />
                                    <input type="text" value={newDesignation} onChange={(e) => setNewDesignation(e.target.value)} />
                                    <button onClick={handleSave}>Save</button>
                                </>
                            ) : (
                                <>
                                    <p>Name: {email.split('@')[0]}</p>
                                    <p>Email: {email}</p>
                                    <p>Phone: {phone}</p>
                                    {/* <p>Designation: {designation}</p> */}
                                </>
                            )}
                        </div>
                        <div>
                            <button className='editBtn' onClick={handleEdit}><MdModeEdit /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
