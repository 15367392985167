import Embed from "@editorjs/embed";
import NestedList from "@editorjs/nested-list";
import Paragraph from "@editorjs/paragraph";
import Warning from "@editorjs/warning";
import Code from "@editorjs/code";
import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import SimpleImage from "@editorjs/simple-image";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import ChangeCase from "editorjs-change-case";

import Tooltip from "editorjs-tooltip";

// import Table from 'editorjs-table';
import Header from "editorjs-header-with-anchor";

import Underline from "@editorjs/underline";

import {uploadFile, uploadFileFromUrl} from "./Services/storage_service";

export const EDITOR_JS_TOOLS = {
    // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
    tooltip: {
        class: Tooltip,
        config: {
            location: "left",
            highlightColor: "#FFEFD5",
            underline: true,
            backgroundColor: "#154360",
            textColor: "#FDFEFE",
            holder: "editorId",
        },
    },
    underline: Underline,
    paragraph: {
        class: Paragraph,
        inlineToolbar: true,
        tunes: ["anyTuneName"],
    },
    changeCase: {
        class: ChangeCase,
        config: {
            showLocaleOption: true,
            locale: "IN",
        },
    },
    embed: {
        class: Embed,
        config: {
            services: {
                youtube: true,
                instagram: true,
                facebook: true,
                twitter: true,
                gfycat: true,
                pinterest: true,
            },
        },
    },
    list: {
        class: NestedList,
        inlineToolbar: true,
        tunes: ["anyTuneName"],
    },
    warning: Warning,
    code: Code,
    linkTool: {
        class: LinkTool,
        config: {
            endpoint: "http://localhost:8008/fetchUrl",
        },
    },
    image: {
        class: Image,
        config: {
            uploader: {
                /**
                 * Upload file to the server and return an uploaded image data
                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                 * @return {Promise.<{success, file: {url}}>}
                 */
                uploadByFile(file) {
                    // your own uploading logic here
                    return uploadFile(file).then((downloadUrl) => {
                        console.log("durl", downloadUrl);
                        return {
                            success: 1,
                            file: {
                                url: downloadUrl,
                                // any other image data you want to store, such as width, height, color, extension, etc
                            },
                        };
                    });
                },

                /**
                 * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
                 * @param {string} url - pasted image URL
                 * @return {Promise.<{success, file: {url}}>}
                 */
                uploadByUrl(url) {
                    // your ajax request for uploading
                    return uploadFileFromUrl(url).then((downloadUrl) => {
                        console.log("download  url", downloadUrl);
                        return {
                            success: 1,
                            file: {
                                url: downloadUrl,
                                // any other image data you want to store, such as width, height, color, extension, etc
                            },
                        };
                    });
                },
            },
        },
    },
    raw: Raw,
    header: {
        class: Header,
        inlineToolbar: ["marker", "link", "underline"],
        tunes: ["anyTuneName"],
        config: {
            placeholder: "Header",
            allowAnchor: true,
            anchorLength: 100,
        },
    },
    quote: Quote,
    marker: Marker,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
    anyTuneName: {
        class: AlignmentTuneTool,
        config: {
            default: "left",
            blocks: {
                header: "left",
                list: "left",
            },
        },
    },
};
