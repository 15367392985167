import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore, initializeFirestore, persistentLocalCache} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBO2Eaoy2HtU5lWJP4T-PhSrgcnYnXAQBQ",
    authDomain: "findflamesapp.firebaseapp.com",
    projectId: "findflamesapp",
    storageBucket: "findflamesapp.appspot.com",
    messagingSenderId: "807607125271",
    appId: "1:807607125271:web:51e954a853a70993242352",
    measurementId: "G-CTJ2ZF023D"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

// const db = getFirestore(app);
const db = initializeFirestore(app, {localCache: persistentLocalCache(/*settings*/{})});

export const storage = getStorage(app);
const firestore = getFirestore(app); // Exporting firestore directly

export {auth, app, db, getFirestore, firestore}; // Exporting firestore