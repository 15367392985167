import React, {useEffect, useState} from "react";
import "./Blog_list.css";
import BlogItem from "./Blog_item/Blog_item";
import {collection, getDocs, onSnapshot, orderBy, query, startAfter, where} from "firebase/firestore";
import {db} from "../../../firebase";

const BlogList = () => {

    const cardsPerPage = 9;

    const [list, setList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [lastVisible, setLastVisible] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    let blogCollectionSubs;

    useEffect(() => {
        setCurrentPage(1); // Reset current page when list changes
    }, [list]);


    useEffect(() => {
        console.log("use effect called")
        const selectElement = document.querySelector('.filter-select');

        selectElement.addEventListener('change', handleFilterChange);

        const unsubscribe = getBlogsMetaData();

        return () => {
            selectElement.removeEventListener('change', handleFilterChange);
            unsubscribe();
        };
    }, []);

    const getBlogsMetaData = (filter) => {
        console.log("Get blogs meta data called")
        // FixMe: implement pagination {done}
        let q;
        if (filter === undefined || filter === "latest") {
            q = query(
                collection(db, "blogMetaData"),
                orderBy("lastUpdate", "desc"),
            );
        } else if (filter === "oldest") {
            q = query(
                collection(db, "blogMetaData"),
                orderBy("lastUpdate", "asc"),
            );
        } else if (filter === "published") {
            q = query(
                collection(db, "blogMetaData"),
                where("published", "==", true),
                orderBy("lastUpdate", "desc")
            );
        } else if (filter === "not-published") {
            q = query(
                collection(db, "blogMetaData"),
                where("published", "==", false),
                orderBy("lastUpdate", "desc")
            );
        }

        blogCollectionSubs = onSnapshot(q, (querySnapshot) => {
            const newList = [];
            console.log(querySnapshot.docs.length)

            if (querySnapshot.docs.length === 0){
                return;
            }

            const lastVisible =
                querySnapshot.docs[querySnapshot.docs.length - 1].data().lastUpdate;

            querySnapshot.docs.forEach(function (doc) {
                const data = doc.data();
                newList.push(data);
            });

            setList(newList);
            setLastVisible(lastVisible);
        });
        return blogCollectionSubs;
    };

    const handleFilterChange = (event) => {
        console.log("Filter changed")
        const selectedValue = event.target.value;
        getBlogsMetaData(selectedValue)
    };


    const fetchMoreBlogs = async () => {
        console.log("last", lastVisible);
        if (!lastVisible) {
            return;
        }
        const q = query(
            collection(db, "blogMetaData"),
            orderBy("lastUpdate", "desc"),
            startAfter(lastVisible),
        );

        const querySnapshot = await getDocs(q);
        const newList = [];
        const lastDocRef = querySnapshot.docs[querySnapshot.docs.length - 1];
        let updatedLastVisible = null;
        if (lastDocRef) {
            updatedLastVisible = lastDocRef.data().lastUpdate;
        }
        querySnapshot.docs.forEach(function (doc) {
            const data = doc.data();
            newList.push(data);
        });
        const updatedList = list.concat(newList);
        setList(updatedList);
        setLastVisible(updatedLastVisible);
    };

    const handleSearch = async () => {
        if (searchQuery) {
            const filteredList = list.filter((blog) =>
                blog.title.toLowerCase().includes(searchQuery.toLowerCase().trim())
            );
            setList(filteredList);
        } else {
            getBlogsMetaData();
        }
    };

    const handleSearchInputChange = (event) => {
        const inputValue = event.target.value;
        setSearchQuery(inputValue);

        if (inputValue === "") {
            getBlogsMetaData();
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };

    const startIndex = (currentPage - 1) * cardsPerPage;
    const endIndex = startIndex + cardsPerPage;
    const currentCards = list.slice(startIndex, endIndex);

    const totalPages = Math.ceil(list.length / cardsPerPage);
    const pageNumbers = Array.from({length: totalPages}, (_, i) => i + 1);

    return (
        <div>
            <div className="features">
                <div className="search-container">
                    <input
                        className="search-input"
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Search by title..."
                    />
                    <button className="handleSearchBtn" onClick={handleSearch}>
                        🔍
                    </button>
                </div>
                <div className="filter-container">
                    <select className="filter-select" onChange={handleFilterChange}>
                        <option className="option-filter" value="latest">default</option>
                        <option className="option-filter" value="latest">latest</option>
                        <option className="option-filter" value="oldest">oldest</option>
                        <option className="option-filter" value="published">published</option>
                        <option className="option-filter" value="not-published">not published</option>
                    </select>
                </div>
            </div>

            <div className="container-fluid">
                <div className="blogList-wrap">
                    <div className="row">
                        {currentCards.map((blog) => (
                            <BlogItem key={blog.id} blog={blog}/>
                        ))}
                    </div>
                    <div className="pagenav-section">
                        <div className="left-pagenav">
                            <p className="left-pagenav-text">Total Pages: {totalPages}</p>
                        </div>
                        <div className="pagination">
                            <a href="#" onClick={() => {
                                if (currentPage === 1) {
                                    return;
                                } else {
                                    setCurrentPage(currentPage - 1);
                                }
                            }}>
                                &laquo;
                            </a>
                            {pageNumbers.map((page) => (
                                <a
                                    href="#"
                                    key={page}
                                    className={page === currentPage ? "active" : ""}
                                    onClick={() => setCurrentPage(page)}
                                >
                                    {page}
                                </a>
                            ))}
                            <a href="#" onClick={() => {
                                if (currentPage === pageNumbers.length) {
                                    return;
                                } else {
                                    setCurrentPage(currentPage + 1);
                                }
                            }}>
                                &raquo;
                            </a>
                        </div>
                        <div className="right-pagenav">
                            <a className="right-pagenav-text" onClick={() => setCurrentPage(pageNumbers.length)}>
                                Go to page: {pageNumbers.length}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogList;