import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../firebase";


async function uploadFile(file) {
    console.log("called upload ", file.name)

    const fileName = `website/blog/${file.name}`;

    const imageRef = ref(storage, fileName);

    await uploadBytes(imageRef, file);

    return getDownloadURL(imageRef);

}

async function uploadFileFromUrl(url) {

    // const fileName = `images/blog/${url.split('/').pop()}`;

    // console.log('file name ', fileName)


    // const imageRef = ref(storage, fileName);


    // console.log('url', url)


    // var blob = await fetch(url, {
    //     mode: 'no-cors',
    // })
    //     .then(response => {
    //         console.log(response)
    //         response.blob()
    //     });

    return url;


    // await uploadBytes(imageRef, blob);


    // return getDownloadURL(imageRef);


}


export {uploadFile, uploadFileFromUrl}


