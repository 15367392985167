import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import {
  ref,
  getDownloadURL,
} from "firebase/storage";
import { auth, storage, firestore } from '../../firebase';
import './HomePage.css'


const HomePage = () => {
  const [email, setEmail] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Fetch user's profile data and image URL on component mount
    const fetchUserData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          setEmail(currentUser.email);
          const userDocRef = firestore.doc(`users/${currentUser.uid}`);
          const userData = await userDocRef.get();
          if (userData.exists()) {
            const userDataObj = userData.data();
            setImageUrl(userDataObj.imageUrl || '');
          }
          const imageRef = ref(storage, `images/${currentUser.email}/displaypic`);
          const url = await getDownloadURL(imageRef);
          setImageUrl(url);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const getTimeOfDay = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) return 'Morning';
    if (hour >= 12 && hour < 17) return 'Afternoon';
    if (hour >= 17 && hour < 20) return 'Evening';
    return 'Night';
  };

  return (
    <div>
      <Header />
      <div className="dashboard-container">
        <h1>Dashboard</h1>
        <h1>
          Good {getTimeOfDay()}, {email.split('@')[0]}!
        </h1>
      </div>
    </div>
  );
};

export default HomePage;
