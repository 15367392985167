import React from 'react'
import BlogContent from './BlogContent/BlogContent.jsx';
import {useParams} from "react-router";

const BlogArticle = () => {
    const {slug} = useParams();
    return (
        <div>
            <BlogContent slug={slug}/>
        </div>
    )
}

export default BlogArticle
