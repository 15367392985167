import React from "react";
import BlogList from "../../Components/Blog_home/Blog_list/BlogList";
import Header from "../../Components/Header/Header";
import "./BlogPage.css";
import {Link} from "react-router-dom";

function BlogHome() {
    // FixMe: follow proper className
    return (
        <>
            <Header/>
            <div>
                <Link to="/blog/add" className="Addblog-btn">
                    Add blog
                </Link>
                <BlogList/>
            </div>
        </>
    );
}

export default BlogHome;
