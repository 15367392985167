import React from "react";
import "./CategoryTag.css";

function CategoryTag(props) {
    return (
        <div className="tag-container">
            <p className="tag-delete" onClick={props.onDelete}>
                X
            </p>
            <p className="tag-text">{props.text}</p>
        </div>
    );
}

export default CategoryTag;
