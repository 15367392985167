import React from "react";
import {Link} from "react-router-dom";
import Chip from "../../../common/chip/chip";
import "./Blog_item.css";

const BlogItem = ({blog}) => {

    const truncateText = (text, lines) => {
        const words = text.split(" ");
        const truncatedWords = words.slice(4, lines * 4); // Assuming each line has around 3 words
        const truncatedText = truncatedWords.join(" ");
        return truncatedText;
    };

    const truncatedExcerpt = truncateText(blog.excerpt, 7);

    return (
        <Link className="blogItem-link" to={`/blog/edit/${blog.blogId}`}>
            <div className="blogItem-wrap card">
                <div className="img-container">
                    <img
                        className="blogItem-cover"
                        src={
                            blog.featureImage
                                ? blog.featureImage
                                : "/assets/images/No_image_available.svg.webp"
                        }
                        alt="cover"
                    />
                    <h5 className="published-text">
                        {blog.published ? "Published" : "Not Published"}
                    </h5>
                </div>

                <div className="card-body">
                    <div className="card-category-box">
                        {blog.categories
                            ? blog.categories.map((val, index) => (
                                <Chip key={index} label={val}/>
                            ))
                            : null}
                    </div>
                    <h3>{blog.title}</h3>
                    <p className="blogItem-desc">{truncatedExcerpt}</p>
                    <footer>
                        <div className="blogItem-author">
                            <img
                                src={
                                    blog.author.profileImage
                                        ? blog.author.profileImage
                                        : "/assets/images/No_image_available.svg.webp"
                                }
                                alt="avatar"
                            />
                            <div>
                                <h6>{blog.author.name}</h6>
                                <p>{blog.lastUpdate.toDate().toLocaleString()}</p>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </Link>
    );
};

export default BlogItem;
