import React, { useState, useEffect } from 'react';
import './ProfilePage.css';
import Header from '../../Components/Header/Header';
import { auth, storage, firestore } from '../../firebase'; 
import { doc, getDoc, setDoc } from "firebase/firestore"; // Correct Firestore imports
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";

const ProfilePage = () => {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('123-456-7890');
  const [designation, setDesignation] = useState('Software Engineer');
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [newDesignation, setNewDesignation] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const [imageUpload, setImageUpload] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          setEmail(currentUser.email);
          const userDocRef = doc(firestore, `users/${currentUser.uid}`);
          const userData = await getDoc(userDocRef);
          if (userData.exists()) {
            const userDataObj = userData.data();
            setName(userDataObj.name || '');
            setPhone(userDataObj.phone || '');
            setDesignation(userDataObj.designation || '');
            setImageUrl(userDataObj.imageUrl || '');
          }
          const imageRef = ref(storage, `website/${currentUser.email}/displaypic`);
          const url = await getDownloadURL(imageRef);
          setImageUrl(url);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const uploadFile = async () => {
    if (imageUpload == null) return;
    const currentUser = auth.currentUser;
    if (currentUser) {
      const imageRef = ref(storage, `website/${currentUser.email}/displaypic`);
      try {
        const snapshot = await uploadBytes(imageRef, imageUpload);
        const url = await getDownloadURL(snapshot.ref);
        setImageUrl(url);
        // Save image URL to user's profile data
        const userDocRef = doc(firestore, `users/${currentUser.uid}`);
        await setDoc(userDocRef, { imageUrl: url }, { merge: true });
        alert('Image uploaded successfully');
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Error uploading image');
      }
    }
  };

  const handleEdit = () => {
    setEditMode(true);
    setNewName(name);
    setNewEmail(email);
    setNewPhone(phone);
    setNewDesignation(designation);
  };

  const handleSave = async () => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDocRef = doc(firestore, `users/${currentUser.uid}`);
        await setDoc(userDocRef, {
          name: newName,
          phone: newPhone,
          designation: newDesignation,
        }, { merge: true });
        setName(newName);
        setPhone(newPhone);
        setDesignation(newDesignation);
        setEditMode(false);
      }
    } catch (error) {
      console.error('Error saving user data:', error);
      alert('Error saving user data');
    }
  };

  return (
    <div>
      <Header />
      <div className="profile-page">
        <div className="left-section">
          <input
            type="file"
            onChange={(event) => {
              setImageUpload(event.target.files[0]);
            }}
          />
          <button onClick={uploadFile}>Upload Image</button>
          {imageUrl && <img src={imageUrl} alt="Profile Picture" />}
        </div>
        <div className="right-section">
          {editMode ? (
            <>
              <input type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
              <input type="email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
              <input type="tel" value={newPhone} onChange={(e) => setNewPhone(e.target.value)} />
              <input type="text" value={newDesignation} onChange={(e) => setNewDesignation(e.target.value)} />
              <button onClick={handleSave}>Save</button>
            </>
          ) : (
            <>
              <p>Name: {name}</p>
              <p>Email: {email}</p>
              <p>Phone: {phone}</p>
              <p>Designation: {designation}</p>
              <button onClick={handleEdit}>Edit</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
