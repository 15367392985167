import React, {useState} from "react";
import CategoryTag from "../CategoryTag/CategoryTag";
import "./CategoryInputField.css";

function CategoryInputField(props) {
    const [category, setCategory] = useState("");

    const handleKeyUp = (e) => {
        if (e.keyCode == 13 && category.length > 0) {
            props.onCategoryAdd(category);
            setCategory("");
        }
    };

    return (
        <div className="category-group">
            <div className="category-group-form">
                <label>Enter Categories</label>
                <input
                    ref={props.inputRef}
                    onKeyUp={handleKeyUp}
                    type="text"
                    className="form-control"
                    placeholder="Categories"
                    value={category}
                    onChange={(event) => setCategory(event.target.value)}
                />
            </div>
            <div className="category-tag-group">
                {props.categories.map((val, index) => {
                    return (
                        <CategoryTag
                            key={index}
                            text={val}
                            onDelete={() => props.handleDelete(index)}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default CategoryInputField;
