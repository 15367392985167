import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import LoginPage from "./Pages/LoginPage/LoginPage";
import HomePage from "./Pages/Home/HomePage";
import BlogHome from "./Pages/Blog/BlogHome";
import AddBlog from "./Pages/Blog/AddBlog";
import EditBlog from "./Pages/Blog/EditBlog";
import {AuthProvider} from "./AuthContext";
import PrivateRoute from "./PrivateRoute";
import PreviewBlog from "./Pages/Blog/components/BlogArticle";
import ProfilePage from "./Pages/Profile/ProfilePage";

function App() {
    return (
        <div className="App">
            <Router>
                <AuthProvider>
                    <Routes>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/" element={<PrivateRoute><HomePage/></PrivateRoute>}/>
                        <Route path="/blog" element={<PrivateRoute><BlogHome/></PrivateRoute>}/>
                        <Route path="/profile" element={<PrivateRoute><ProfilePage/></PrivateRoute>}/>
                        <Route path="/blog/add" element={<PrivateRoute><AddBlog/></PrivateRoute>}/>
                        <Route path="/blog/edit/:id" element={<PrivateRoute><EditBlog/></PrivateRoute>}/>
                        <Route path="/preview/:slug" element={<PrivateRoute><PreviewBlog/></PrivateRoute>}/>
                        <Route path="*" element={<PrivateRoute><HomePage/></PrivateRoute>}/>
                    </Routes>
                </AuthProvider>
            </Router>
        </div>
    );
}

export default App;
