import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CategoryInputField from "../../Components/CategoryInputField/CategoryInputField.js";
import Editor from "../../Components/Editor";
import { fetchBlogById, updateBlog, deleteBlog } from "../../Services/firestore_service"; // Import deleteBlog function
import "./EditBlog.css";
import { LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { uploadFile } from "../../Services/storage_service";
import {
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { auth, storage, firestore } from '../../firebase';

function EditBlog() {
  // const {  } = useParams();
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const initialData = location.state;
  const editorCore = React.useRef(null);
  const titleInput = React.createRef();
  const featureImageInput = React.createRef();
  const slugInput = React.createRef();
  const excerptInput = React.createRef();
  const categoryInput = React.createRef();
  const [featureImage, setFeatureImage] = useState(
    !initialData ? null : initialData.featureImage
  );
  const [content, setContent] = useState(
    !initialData ? null : initialData.content
  );
  const [title, setTitle] = useState(!initialData ? "" : initialData.title);
  const [featured, setFeatured] = useState(
    !initialData ? "" : initialData.featured
  );
  const [slug, setSlug] = useState(!initialData ? "" : initialData.slug);
  const [excerpt, setExcerpt] = useState(
    !initialData ? "" : initialData.excerpt
  );
  const [progress, setProgress] = useState(0);
  const [metaDataId, setMetaDataId] = useState(
    !initialData ? "" : initialData.metaDataId
  );

  const [published, setPublished] = useState(
    !initialData ? "" : initialData.published
  );

  const [createdOn, setcreatedOn] = useState(
    !initialData ? "" : initialData.createdOn
  );
  const [categoriesArray, setcategoriesArray] = useState(
    !initialData ? [] : initialData.categories
  );

  const [exists, setExists] = useState(-1);

  const author = {
    name: "Saumya Kumar",
    profileImage: "https://picsum.photos/200",
  };

  // adding user
  const [email, setEmail] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Fetch user's profile data and image URL on component mount
    const fetchUserData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          setEmail(currentUser.email);
          const userDocRef = firestore.doc(`users/${currentUser.uid}`);
          const userData = await userDocRef.get();
          if (userData.exists()) {
            const userDataObj = userData.data();
            setImageUrl(userDataObj.imageUrl || '');
          }
          const imageRef = ref(storage, `images/${currentUser.email}/displaypic`);
          const url = await getDownloadURL(imageRef);
          setImageUrl(url);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handlePreview = () => {
    const data = gatherBlogData(false);
    localStorage.setItem("previewData", JSON.stringify(data));
    navigate(`/preview/${slug}`);
  };

  useEffect(() => {
    fetchBlogById(id).then((blog) => {
      if (blog == null) {
        // console.log("no blog show 404");
        setExists(0);
      } else {
        // console.log("Have blog", blog);
        setTitle(blog.title);
        setcategoriesArray(blog.categories);
        setSlug(blog.slug);
        setExcerpt(blog.excerpt);
        setFeatureImage(blog.featureImage);
        setMetaDataId(blog.metaDataId);
        setFeatured(blog.featured);
        setPublished(blog.published);
        setcreatedOn(blog.createdOn);
        setExists(1);
        setContent(blog.content);
      }
    });
  }, [id]);

  function handleImageSelection(e) {
    // console.log(e.target.files);
    var imageUrl = URL.createObjectURL(e.target.files[0]);
    setFeatureImage(imageUrl);
    setProgress(1);
    uploadFile(e.target.files[0])
      .then((url) => {
        // console.log("image url", url);
        setFeatureImage(url);
        setProgress(0);
      })
      .catch((e) => {
        setProgress(0);
        setFeatureImage(null);
      });
  }

  const handleInitialize = React.useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleSave = (event) => {
    event.preventDefault();

    editorCore.current.save().then((editorData) => {
      setContent(editorData);

      const data = gatherBlogData(editorData);
      console.log(data)

      if (published) {
        if (!validateFields(data)) {
          // validate all field before publish
          return;
        }
      }

      // at least have title to save

      if (title.length > 0) {
        updateBlog(data)
          .then(() => {
            if (published) {
              navigate("/blog");
            } else {
              toast.success("Saved Successfully 👏", { autoClose: 1000 });
            }
          })
          .catch((e) => {
            // console.log(e);
            toast.error("Some error occurred. 🚫", { autoClose: 3000 });
          });
      } else {
        toast.error("Provide Title");
        titleInput.current.focus();
      }
    });
  };

  const validateFields = (data) => {
    // console.log("validating data", data);
    if (data.title.length < 1) {
      titleInput.current.focus();
      toast.error("Please Provide title");
      return false;
    } else if (data.categories.length < 1) {
      categoryInput.current.focus();
      toast.error("Please Provide a category");
      return false;
    } else if (data.slug.length < 1) {
      slugInput.current.focus();
      toast.error("Please Provide slug");
      return false;
    } else if (data.excerpt.length < 1) {
      excerptInput.current.focus();
      toast.error("Please Provide Excerpt");
      return false;
    } else if (data.featureImage == null) {
      featureImageInput.current.focus();
      toast.error("Please Provide feature image");
      return false;
    } else if (data.content.blocks.length < 1) {
      toast.error("Please Provide Content");
      return false;
    } else {
      return true;
    }
  };

  const gatherBlogData = (savedData) => {
    return {
      id: id,
      published: published,
      title: title,
      featured: featured,
      author: author,
      categories: categoriesArray,
      slug: slug,
      excerpt: excerpt,
      featureImage: featureImage,
      metaDataId: metaDataId,
      content: savedData ? savedData : content,
    };
  };

  const handleCategoryAdd = (category) => {
    setcategoriesArray([...categoriesArray, category]);
  };

  const handleDelete = (index) => {
    // console.log("handle delete claled", index);
    categoriesArray.splice(index, 1);
    setcategoriesArray([...categoriesArray]);
  };

  const handleDeleteBlog = () => {
    deleteBlog(id)
      .then(() => {
        toast.success("Blog deleted successfully");
        navigate("/blog");
      })
      .catch((error) => {
        toast.error("Failed to delete blog");
        console.error("Error deleting blog:", error);
      });
  };

  // console.log("HERE", featured);

  if (exists === -1) {
    return (
      <Box>
        <LinearProgress />
      </Box>
    );
  } else if (exists === 1) {
    return (
      <div className="editblog">
        <h3 className="editblog-text">
          {published ? "Published" : "Not Published"}
        </h3>
        <div className="edit-form-group">
          <label>Blog Title</label>
          <input
            ref={titleInput}
            type="text"
            className=""
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Title"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <CategoryInputField
            inputRef={categoryInput}
            categories={categoriesArray}
            onCategoryAdd={handleCategoryAdd}
            handleDelete={handleDelete}
          />
          <label>Slug</label>
          <input
            type="text"
            ref={slugInput}
            value={slug}
            onChange={(e) => setSlug(e.target.value.replace(/[^a-z-]/g, ''))}
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="slugHelp"
            placeholder="Slug (ex:- prints-in-python, what-is-saas)"
            required
          />
          <br />
          <small id="slugHelp" className="slugtext">
            (A url friendly text for the blog)
          </small>
          <br />
          <div className="excerpt-container">
            <label>Excerpt</label>
            <textarea
              type="text"
              ref={excerptInput}
              value={excerpt}
              onChange={(e) => setExcerpt(e.target.value)}
              className="form-control "
              id="exampleInputEmail1"
              aria-describedby="excerptHelp"
              placeholder="Excerpt"
              required
            />
          </div>
          <br />
          <small id="excerptHelp" className="excerpttext">
            (Small description ofcontent)
          </small>
          <br />

          <div className="featured">
            <label className="feature-head">Featured Image</label>
            <div className="feature-img-container">
              <Box sx={{ width: "100%" }}>
                {progress ? <LinearProgress /> : null}
              </Box>
              <div className="image-area">
                {featureImage ? (
                  <img
                    className="blog-image"
                    src={featureImage}
                    alt="featureimg"
                    width={400}
                    height={450}
                  />
                ) : (
                  <input
                    ref={featureImageInput}
                    type="file"
                    accept="image/*"
                    onChange={handleImageSelection}
                    className="add-image"
                  />
                )}
              </div>
            </div>

            {featureImage ? (
              <button
                type="button"
                className="remove-image-btn"
                onClick={() => setFeatureImage(null)}
              >
                Remove Image
              </button>
            ) : null}
          </div>
        </div>

        {/* Editorjs container */}
        <div className="content-container">
          <Editor
            onInit={handleInitialize}
            initalData={content ? content : { blocks: [] }}
            id={"editorId"}
          />
          <div id="editor"></div>
        </div>

        {/* <div className="toggle-container"> */}
        <div className="toggle-containe">
          <h3>Published</h3>
          <br></br>
          <input
            checked={published}
            type="radio"
            name="toggle"
            id="toggleTrue"
            onChange={() => setPublished(true)}
          />
          <label htmlFor="toggleTrue">True</label>
          <input
            type="radio"
            name="toggle"
            id="toggleFalse"
            checked={!published}
            onChange={() => setPublished(false)}
          />
          <label htmlFor="toggleFalse">False</label>
        </div>

        <div className="editblog-btm-container">
          <div className="btm-form-group">
            <label>Author</label>
            <div className="author-card">
              <img
                src={author.profileImage}
                className="avatar-image"
                alt="..."
              />
              <h5 className="author-card-title">{email.split('@')[0]}</h5>
            </div>
          </div>

          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className="btn-primary"
              onClick={(e) => handleSave(e)}
            >
              Save
            </button>
            <button type="button" className="btn-primary" onClick={handlePreview}>
              Preview
            </button>
            <button
              type="button"
              className="btn-primary"
              onClick={handleDeleteBlog} // Connect deletion function to button click event
            >
              Delete
            </button>
            <button
              type="button"
              className="btn-primary"
              onClick={() => navigate("/blog")}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    toast.error("This Blog is deleted 🚫", { autoClose: 3000 });
    return <Navigate to="/404" />;
  }
}

export default EditBlog;
